var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"light-blue lighten-5 container",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-center mt-12"},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"9"}},[_c('div',{staticClass:"title-text font-weight-bold"},[_vm._v(" Plan Document Lookup: See Full Plan Details ")])])],1),_c('v-row',{staticClass:"mt-0 d-flex justify-center"},[_c('v-col',{attrs:{"cols":"9"}},[_c('v-divider')],1)],1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"9"}},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"primary"},on:{"click":function($event){_vm.goBack();
          _vm.clickedFieldGlobal(
            'goBackSelectPlanBtn',
            104,
            'American Travel Survey v1'
          );}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" Go Back To Select Plan ")],1)],1)],1),_c('v-row',{staticClass:"mt-0 d-flex justify-center align-center"},[_c('v-col',{attrs:{"cols":"9"}},[_c('v-card',{staticClass:"pa-4 ma-4"},[_c('v-card-text',{staticClass:"text-h4"},[_vm._v(" On this page you can get full plan details, whether or not you've already bought a travel insurance plan. Find the plan name and your state below and click the button to access the most up-to-date plan documents. ")]),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"4"}},[_c('v-select',{attrs:{"label":"Choose Plan","items":_vm.plans},on:{"click":function($event){return _vm.clickedFieldGlobal(
                  'choosePlan',
                  105,
                  'American Travel Survey v1'
                )}},model:{value:(_vm.plan),callback:function ($$v) {_vm.plan=$$v},expression:"plan"}})],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.StateList,"label":"Enter State"},on:{"click":function($event){return _vm.clickedFieldGlobal(
                  'enterState',
                  106,
                  'American Travel Survey v1'
                )}},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"primary white--text font-weight-bold",attrs:{"block":"","x-large":"","disabled":_vm.showButton},on:{"click":function($event){_vm.lookupPlan();
                _vm.clickedFieldGlobal(
                  'lookUpPlan',
                  107,
                  'American Travel Survey v1'
                );}}},[_vm._v(" Look Up Plan ")])],1)],1),(_vm.showPlan)?_c('div',[_c('v-row',{staticClass:"mb-12"},[_c('v-col',{staticClass:"d-flex justify-center title-text"},[_vm._v(" "+_vm._s(_vm.plan)+" Plan for "+_vm._s(_vm.state)+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"primary white--text font-weight-bold",attrs:{"x-large":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                    'downloadPlan',
                    108,
                    'American Travel Survey v1'
                  )}}},[_vm._v(" Download Plan ")])],1)],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }