<template>
  <v-container
    fluid
    class="light-blue lighten-5 container"
  >
    <v-row class="d-flex justify-center mt-12">
      <v-col
        cols="9"
        class="d-flex justify-center"
      >
        <div class="title-text font-weight-bold">
          Plan Document Lookup: See Full Plan Details
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-0 d-flex justify-center">
      <v-col cols="9">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col
        cols="9"
        class="d-flex justify-end"
      >
        <v-btn
          class="font-weight-bold"
          color="primary"
          @click="
            goBack();
            clickedFieldGlobal(
              'goBackSelectPlanBtn',
              104,
              'American Travel Survey v1'
            );
          "
        >
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          Go Back To Select Plan
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0 d-flex justify-center align-center">
      <v-col cols="9">
        <v-card class="pa-4 ma-4">
          <v-card-text class="text-h4">
            On this page you can get full plan details, whether or not you've
            already bought a travel insurance plan. Find the plan name and your
            state below and click the button to access the most up-to-date plan
            documents.
          </v-card-text>
          <v-row class="d-flex justify-center">
            <v-col
              class="d-flex justify-center"
              cols="4"
            >
              <v-select
                v-model="plan"
                label="Choose Plan"
                :items="plans"
                @click="
                  clickedFieldGlobal(
                    'choosePlan',
                    105,
                    'American Travel Survey v1'
                  )
                "
              ></v-select>
            </v-col><v-col
              class="d-flex justify-center"
              cols="4"
            >
              <v-autocomplete
                v-model="state"
                :items="StateList"
                label="Enter State"
                @click="
                  clickedFieldGlobal(
                    'enterState',
                    106,
                    'American Travel Survey v1'
                  )
                "
              >
              </v-autocomplete>
            </v-col><v-col
              class="d-flex justify-center"
              cols="4"
            >
              <v-btn
                block
                class="primary white--text font-weight-bold"
                x-large
                :disabled="showButton"
                @click="
                  lookupPlan();
                  clickedFieldGlobal(
                    'lookUpPlan',
                    107,
                    'American Travel Survey v1'
                  );
                "
              >
                Look Up Plan
              </v-btn>
            </v-col>
          </v-row>
          <div v-if="showPlan">
            <v-row class="mb-12">
              <v-col class="d-flex justify-center title-text">
                {{ plan }} Plan for {{ state }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  class="primary white--text font-weight-bold"
                  x-large
                  @click="
                    clickedFieldGlobal(
                      'downloadPlan',
                      108,
                      'American Travel Survey v1'
                    )
                  "
                >
                  Download Plan
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  var states = require('ustates')
  export default {
    data () {
      return {
        showPlan: false,
        plan: '',
        state: '',
        plans: ['Standard', 'Preferred', 'Premium'],
        places: states
      }
    },
    computed: {
      StateList () {
        let statelist = Object.values(states)
        statelist = statelist.slice(0, 51)
        let list = statelist.map(entry => entry.name)
        console.log(list)
        return list
      },
      showButton () {
        return !(this.plan && this.state)
      }
    },
    methods: {
      goBack () {
        this.$gtag.event('TravelPoliciesUsa', { action: 'goBackToSelectPlan' })
        this.$router.push({ path: '/travel/selectplanusa' })
      },
      lookupPlan () {
        this.showPlan = true
      }
    }
  }
</script>

<style lang="scss" scoped>
.title-text {
  font-size: 36px;
  font-weight: 400;
  color: #00a1b7;
}
.container {
  height: 80%;
}
</style>
